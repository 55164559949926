import * as React from 'react';

const FAQData = [
  {
    title: 'What is Build To Rent?',
    value: [
      {
        title: 'What are Build To Rent properties?',
        value: (
          <>
            Build To Rent is a new type of rental properties that is built,
            owned and managed by institutional landlords and property managers.
            This is different from traditional private letting where an
            individual landlord owns the property and manages it himself, or
            instructs an agent to manage it.
          </>
        ),
      },
      {
        title:
          'How are Build To Rent properties different from traditional private lets?',
        value: (
          <>
            Housing quality and scale of operations are the key differences.
            Most Build To Rent properties were built in the last 2-4 years
            following strict quality standards and are regularly maintained as
            part of the whole development. A private-let property could be
            ex-council and much older with maintenance subject to landlords&#39;
            budget.
            <br />
            <br />
            When something goes wrong in a Build To Rent property, onsite
            management has readily available tools and resources for immediate
            fix. A private landlord can be slower and less responsive.
          </>
        ),
      },
      {
        title: 'What is the Build To Rent company?',
        value: (
          <>
            We are a community edited database of Build to Rent and co-living
            developments the UK. We do not let or manage property, instead we
            aggregate public data of the developments and list their managers
            along with links to their websites and direct contact details. Our
            content-rich, data-driven platform helps tenants research their next
            rental homes in depth and connect with landlords directly. Customer
            feedbacks create invaluable insights for prospective tenants and
            allow landlords to tailor their future developments to best fit
            upcoming market trends.
          </>
        ),
      },
      {
        title: 'What is your database about?',
        value: (
          <>
            Our database lists all Build to Rent and co-living developments in
            the UK. Anyone can add new developments to the database. We collect
            all kinds of information about existing buildings in the UK -
            photos, videos, details, stories, area guides, customer insights and
            much more.
          </>
        ),
      },
      {
        title: 'What type of developments are in your database?',
        value: (
          <>
            Those that were built specifically for residential letting purposes.
          </>
        ),
      },
    ],
  },
  {
    title: 'Landlords & Managers',
    value: [
      {
        title: 'Why should I upload a building/company?',
        value: (
          <>
            The most common issue in the Build To Rent sector is that
            prospective tenants are not aware of this sector and the potential
            benefits it can bring. By listing on our platform together with
            like-minded businesses, you will receive new leads and customer
            analytics to improve your developments&#39; performances.
          </>
        ),
      },
      {
        title:
          'I am a landlord/manager of one of the buildings in your database. How can I add our company name on the building page?',
        value: (
          <>
            Please <a href="mailto:info@buildtorent.io">email us</a> the address
            and company name and any other details you would like to add.
          </>
        ),
      },
      {
        title: 'Can I take full control of a development page?',
        value: (
          <>
            We offer options for landlords and managers to help promote a
            development to potential renters.
          </>
        ),
      },
      {
        title: 'Is it free to list my company or developments?',
        value: <>Yes. It is free to list.</>,
      },
      {
        title: 'How to add a development?',
        value: (
          <>
            Register with us or send property details to{' '}
            <a href="mailto:info@buildtorent.io">info@buildtorent.io</a>.
          </>
        ),
      },
      {
        title: 'Who can add new developments?',
        value: <>Everyone can add new developments.</>,
      },
    ],
  },
  {
    title: 'Prospective Tenants',
    value: [
      {
        title: 'Can I view a property before renting?',
        value: (
          <>
            Yes, you are free to arrange everything with the landlords and
            property managers.
          </>
        ),
      },
      {
        title: 'Who are my landlord and property manager?',
        value: (
          <>
            You will be renting from the largest Build To Rent landlords and
            property managers in the UK and globally, managing over 100,000
            properties at 600 developments. All of them were established for one
            single purpose: to delivery the best housing solutions for
            customers.
          </>
        ),
      },
      {
        title:
          'Who do I contact, sign contract with and inform of maintenance issues?',
        value: (
          <>
            You will contact and sign a rental agreement with the landlord or
            managers of the development you wish to live in. They are the ones
            responsible for any maintenance issues.
          </>
        ),
      },
    ],
  },
];

export default FAQData;
