import { useMutation, UseMutationOptions } from '@tanstack/react-query';

export interface SendEmailFetcherProps {
  data: {
    to: string;
    subject: string;
    from?: string;
    text?: string;
    html?: string;
  };
}

interface FetcherSuccessResponse {
  data: {
    code: number;
    data: {
      accepted: string[];
      rejected: string[];
      envelopeTime: number;
      messageTime: number;
      messageSize: number;
      response: string;
      envelope: {
        from: string;
        to: string[];
      };
      messageId: string;
    };
  };
}

interface FetcherFailureResponse {
  error: {
    code: number;
    message: string;
  };
}

export async function sendEmail({
  data,
}: SendEmailFetcherProps): Promise<FetcherSuccessResponse> {
  const url = `${process.env.GATSBY_SEND_EMAIL_HOST}`;

  const res = await fetch(url, {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ from: 'info@buildtorent.io', ...data }),
  });

  if (!res.ok) {
    const json: FetcherFailureResponse = await res.json();

    throw new Error(json.error.message);
  }

  return res.json() as Promise<FetcherSuccessResponse>;
}

export default function useSendEmail(
  queryConfig?: UseMutationOptions<
    FetcherSuccessResponse,
    Error,
    SendEmailFetcherProps
  >
) {
  return useMutation<FetcherSuccessResponse, Error, SendEmailFetcherProps>(
    sendEmail,
    queryConfig
  );
}
