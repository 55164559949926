import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    infoContactUs: {
      paddingTop: 40,
    },
    info: {
      '& a': {
        color: theme.palette.gray.dark,
        fontSize: '1.5625rem',
        textDecoration: 'none',
        fontWeight: 600,
        [theme.breakpoints.down('md')]: {
          fontSize: '1.25rem',
        },
        '& svg': {
          verticalAlign: 'middle',
        },
      },
    },
    text: {
      fontSize: '1.25rem',
      color: theme.palette.gray.dark,
      margin: '7px 0',
      [theme.breakpoints.down('md')]: {
        fontSize: '1.125rem',
      },
    },
    connectWith: {
      color: theme.palette.gray.dark,
      fontSize: '1.5625rem',
      marginBottom: 10,
      [theme.breakpoints.down('md')]: {
        fontSize: '1.25rem',
      },
    },
    listIcon: {
      display: 'flex',
      '& li': {
        width: 48,
        height: 48,
        borderRadius: '100%',
        backgroundColor: '#F9B7A9',
        display: 'flex',
        justifyContent: 'center',
        margin: '0 8px',
        '&:first-child': {
          marginLeft: 0,
        },
        '& div': {
          minWidth: 'auto',
          '& svg': {
            color: '#ffffff',
            verticalAlign: 'middle',
          },
        },
      },
    },
  })
);
