import * as React from 'react';
import {
  Box,
  Button,
  Grid,
  Input,
  InputLabel,
  TextareaAutosize,
} from '@material-ui/core';
import { sanitize } from 'dompurify';
import useSendEmail from '../../../../api/marketing/useSendEmail';
import useStyles from './styles/FormContactUs';
import Snackbar, { SnackbarType } from '../../../molecules/Snackbar';
import useSnackbar from '../../../molecules/Snackbar/useSnackbar';

export default function FormContactUs() {
  const classes = useStyles();

  const { mutate: sendEmail, status: sendEmailStatus } = useSendEmail();

  const { displaySnackbar, hideSnackbar, snackbarState } = useSnackbar();

  React.useEffect(() => {
    if (sendEmailStatus === 'success') {
      displaySnackbar(SnackbarType.SUCCESS, `Successfully sent message 😊!`);
    } else if (sendEmailStatus === 'error') {
      displaySnackbar(
        SnackbarType.ERROR,
        `Could not send message. Please try contact us using the email and phone number displayed on this page.`
      );
    }
  }, [sendEmailStatus]);

  const submitForm: React.FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();
    const formData = new FormData(e.currentTarget);

    const name = formData.get('name') ?? '';
    const email = formData.get('email') ?? '';
    const phone = formData.get('phone') ?? '';
    const help = formData.get('help') ?? '';
    const message = formData.get('message') ?? '';

    const messageIntro = `From ${name}\nEmail: ${email}\nPhone: ${phone}\n\n`;

    const messageFullText = `${messageIntro}${message}`;
    const messageFullHtml = sanitize(
      messageFullText.replace(/(\r\n|\r|\n)/gm, '<br>')
    );

    void sendEmail({
      data: {
        to: 'info@buildtorent.io',
        subject: `Contact Form: ${name} - ${help}`,
        text: messageFullText,
        html: messageFullHtml,
      },
    });
  };

  return (
    <>
      <form className={classes.formContactUs} onSubmit={submitForm}>
        <Grid container className={classes.row}>
          <Grid item xs={12} sm={6} className={classes.p30 + ' ' + classes.pt0}>
            <Box className={classes.formContactUsGroup}>
              <InputLabel htmlFor="name">Your Name</InputLabel>
              <Input
                id="name"
                name="name"
                placeholder="Your Name"
                aria-describedby="my-helper-text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.p30 + ' ' + classes.pt0}>
            <Box className={classes.formContactUsGroup}>
              <InputLabel htmlFor="email">Email</InputLabel>
              <Input
                id="email"
                name="email"
                placeholder="Your Email"
                aria-describedby="my-helper-text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.p30}>
            <Box className={classes.formContactUsGroup}>
              <InputLabel htmlFor="tel">Phone number</InputLabel>
              <Input
                id="tel"
                name="phone"
                placeholder="+44"
                aria-describedby="my-helper-text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} sm={6} className={classes.p30}>
            <Box className={classes.formContactUsGroup}>
              <InputLabel htmlFor="help">How can we help?</InputLabel>
              <Input
                id="help"
                name="help"
                placeholder="Title"
                aria-describedby="my-helper-text"
              />
            </Box>
          </Grid>
          <Grid item xs={12} className={classes.p30}>
            <Box className={classes.formContactUsGroup}>
              <InputLabel htmlFor="message">Add your message</InputLabel>
              <TextareaAutosize
                id="message"
                rowsMin={6.5}
                name="message"
                placeholder="Add a message"
                className={classes.textarea}
              />
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            className={classes.p30 + ' ' + classes.pt20 + ' ' + classes.pb10}
          >
            <Button type="submit" className={classes.btnSend}>
              Send message
            </Button>
          </Grid>
        </Grid>
      </form>

      <Snackbar
        hideSnackbar={hideSnackbar}
        show={snackbarState.show}
        type={snackbarState.type}
      >
        {snackbarState.content}
      </Snackbar>
    </>
  );
}
