import * as React from 'react';
import { Box } from '@material-ui/core';
import SEO from '../components/atoms/SEO';
import GenericPage from '../components/layout/CommonLayout/GenericPage';
import ContactUs from '../components/pages/contact/ContactUs';
import FAQ from '../components/pages/contact/FAQ';
import useAnalyticsSessionData from '../hooks/useAnalyticsSessionData';

export default function Contact() {
  useAnalyticsSessionData();

  return (
    <GenericPage>
      <SEO title="Contact" />
      <Box>
        <ContactUs />
        <FAQ />
      </Box>
    </GenericPage>
  );
}
