import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    grey: {
      // backgroundColor: theme.palette.secondary.light,
    },
    container: {
      maxWidth: theme.custom.container1460,
      padding: 0,
      margin: 'auto',
    },
    titleFaq: {
      fontSize: '2rem',
      color: theme.palette.gray.dark,
      fontWeight: 'bold',
    },
    faqSectionHeader: {
      paddingBottom: 20,
      '& h5': {
        fontSize: '1.75rem',
        color: theme.palette.gray.dark,
        fontWeight: 'bold',
      },
    },
  })
);
