import { Box, Container, Typography, Grid } from '@material-ui/core';
import * as React from 'react';
import FormContactUs from './FormContactUs';
import InfoContactUs from './InfoContactUs';
import useStyles from './styles';

export default function ContactUs() {
  const classes = useStyles();
  return (
    <Box className={classes.contactUs}>
      <Container className={classes.container}>
        <Typography variant="h4" className={classes.title}>
          Contact Us
        </Typography>
        <Box className={classes.boxContactUs}>
          <Grid container className={classes.row}>
            <Grid item sm={12} md={8} lg={9} className={classes.p25}>
              <FormContactUs />
            </Grid>
            <Grid item sm={12} md={4} lg={3} className={classes.p25}>
              <InfoContactUs />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
}
