import { makeStyles, createStyles, Theme } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    formContactUs: {},
    row: {
      margin: '0 -30px',
      width: 'auto',
    },
    p30: {
      padding: '30px',
      [theme.breakpoints.down('xs')]: {
        paddingTop: 0,
      },
    },
    pt0: {
      paddingTop: 0,
    },
    pt20: {
      paddingTop: 20,
    },
    pb10: {
      paddingBottom: 10,
    },
    formContactUsGroup: {
      '& label': {
        fontSize: '1.5625rem',
        color: theme.palette.gray.dark,
        marginBottom: 25,
      },
      '& div': {
        width: '100%',
        '&:before': {
          content: 'none',
        },
        '&:after': {
          content: 'none',
        },
        '& input': {
          border: `1px solid ${theme.palette.primary.main}`,
          height: 61,
          padding: '0 40px',
          fontSize: '1.25rem',
          color: theme.palette.gray.main,
        },
      },
      '& textarea': {
        border: `1px solid ${theme.palette.primary.main}`,
        width: '100%',
        outline: 'none',
        padding: '20px 25px',
        fontFamily: 'Source Sans Pro',
        fontSize: '1.25rem',
        color: theme.palette.gray.main,
        fontWeight: 400,
        resize: 'none',
      },
    },
    btnSend: {
      height: 74,
      background: theme.palette.primary.main,
      width: '100%',
      color: theme.palette.primary.contrastText,
      fontSize: '1.25rem',
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: 'red',
      },
    },
    textarea: {
      left: 0,
      '&::placeholder': {
        color: '#cccccc',
      },
    },
  })
);
