import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  Typography,
} from '@material-ui/core';
import * as React from 'react';
import {
  Email as EmailIcon,
  Facebook as FacebookIcon,
  Instagram as InstagramIcon,
  LinkedIn as LinkedInIcon,
  Twitter as TwitterIcon,
} from '@material-ui/icons';
import useStyles from './styles/InfoContactUs';
import { Link } from 'gatsby';

const socialLinkBlockData = [
  {
    displayName: 'Facebook',
    href: 'https://www.facebook.com/buildtorent.io',
    isExternal: true,
    icon: <FacebookIcon />,
  },
  {
    displayName: 'Instagram',
    href: 'https://www.instagram.com/buildtorent.io',
    isExternal: true,
    icon: <InstagramIcon />,
  },
  {
    displayName: 'Twitter',
    href: 'https://twitter.com/buildtorent_io',
    isExternal: true,
    icon: <TwitterIcon />,
  },
  {
    displayName: 'LinkedIn',
    href: 'https://www.linkedin.com/company/buildtorent-io',
    isExternal: true,
    icon: <LinkedInIcon />,
  },
];

function InfoContactUs() {
  const classes = useStyles();
  return (
    <Box className={classes.infoContactUs}>
      <Box className={classes.info}>
        <a href="mailto:info@buildtorent.io">
          <EmailIcon /> info@buildtorent.io
        </a>
      </Box>
      <Box>
        <Typography variant="h5" className={classes.connectWith}>
          Connect with us
        </Typography>
        <List className={classes.listIcon}>
          {socialLinkBlockData.map((val, i) => (
            <ListItem key={i}>
              <ListItemIcon>
                {val.isExternal ? (
                  <a href={val.href} target="_blank" rel="noreferrer">
                    {val.icon}
                  </a>
                ) : (
                  <Link to={val.href}>{val.icon}</Link>
                )}
              </ListItemIcon>
            </ListItem>
          ))}
        </List>
      </Box>
    </Box>
  );
}

export default InfoContactUs;
