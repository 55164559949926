import { makeStyles, createStyles, Theme, colors } from '@material-ui/core';

export default makeStyles((theme: Theme) =>
  createStyles({
    contactUs: {
      padding: '80px 15px',
      backgroundColor: colors.grey['100'],
      [theme.breakpoints.down('xs')]: {
        padding: '40px 15px 80px',
      },
    },
    container: {
      maxWidth: theme.custom.container1460,
      padding: 0,
      margin: 'auto',
    },
    title: {
      fontSize: '2rem',
      marginBottom: '70px',
      color: theme.palette.gray.dark,
      [theme.breakpoints.down('xs')]: {
        marginBottom: '40px',
      },
    },
    boxContactUs: {
      backgroundColor: '#ffffff',
      padding: '40px 60px 40px',
      borderRadius: 10,
      boxShadow: '0 0 10px rgba(0,0,0,0.1)',
      [theme.breakpoints.down('md')]: {
        padding: '40px 30px 40px',
      },
      [theme.breakpoints.down('xs')]: {
        padding: '40px 15px 40px',
      },
    },
    row: {
      margin: '0 -25px',
      width: 'auto',
    },
    p25: {
      padding: ' 0 25px',
    },
  })
);
