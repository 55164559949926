import * as React from 'react';
import { Box, Container, Typography } from '@material-ui/core';
import useStyles from './FAQ.styles';
import FAQData from './FAQ.config';
import { ExpansionPanel } from '../../../organisms';

const FAQSection = ({ children }: { children?: React.ReactNode }) => {
  return <Box pb={4}>{children}</Box>;
};

const FAQSectionHeader = ({ children }: { children?: React.ReactNode }) => {
  return (
    <Box className={useStyles().faqSectionHeader}>
      <Typography variant="h5">{children}</Typography>
    </Box>
  );
};

const FAQ = () => {
  const classes = useStyles();
  return (
    <Box className={classes.grey} p="70px 15px" width="100%">
      <Container className={classes.container}>
        <FAQSection>
          <Typography id="faq" variant="h4" className={classes.titleFaq}>
            FAQs
          </Typography>
        </FAQSection>
        <br />
        {FAQData.map((faqs, i) => (
          <React.Fragment key={i}>
            <FAQSection>
              <FAQSectionHeader>{faqs.title}</FAQSectionHeader>
              {faqs.value.map((faq, j) => (
                <ExpansionPanel key={j} headerText={faq.title}>
                  <Typography>{faq.value}</Typography>
                </ExpansionPanel>
              ))}
            </FAQSection>
            <br />
          </React.Fragment>
        ))}
      </Container>
    </Box>
  );
};

export default FAQ;
